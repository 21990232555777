export function yandexAddToCart(id: any, price: any, quantity: any, categories: any, name: any) {
  try {
    window.dataLayer = window.dataLayer || [];
    return dataLayer.push({
      ecommerce: {
        currencyCode: "RUB",
        add: {
          products: [{
            id: id,
            name: name,
            price: price,
            category: categories ? categories.map((category, index) => `${index !== 0 ? `/` : ``}${category.name}`).join("") : "",
            quantity: quantity
          }]
        }
      }
    });
  } catch (err) {
    console.log(err);
  }
}
export function yandexRemoveFromCart(id: any, quantity: any, categories: any, name: any) {
  try {
    window.dataLayer = window.dataLayer || [];
    return dataLayer.push({
      ecommerce: {
        currencyCode: "RUB",
        remove: {
          products: [{
            id: id,
            name: name,
            quantity: quantity,
            category: categories ? categories.map((category, index) => `${index !== 0 ? `/` : ``}${category.name}`).join("") : ""
          }]
        }
      }
    });
  } catch (err) {
    console.log(err);
  }
}
export function yandexSubtractFromCart(id: any, quantity: any, categories: any, name: any) {
  try {
    window.dataLayer = window.dataLayer || [];
    return dataLayer.push({
      ecommerce: {
        currencyCode: "RUB",
        remove: {
          products: [{
            id: id,
            name: name,
            quantity: quantity,
            category: categories ? categories.map((category, index) => `${index !== 0 ? `/` : ``}${category.name}`).join("") : ""
          }]
        }
      }
    });
  } catch (err) {
    console.log(err);
  }
}
export function yandexViewItem(id: any, price: any, categories: any, name: any) {
  try {
    window.dataLayer = window.dataLayer || [];
    return dataLayer.push({
      ecommerce: {
        currencyCode: "RUB",
        detail: {
          products: [{
            id: id,
            name: name,
            price: price,
            category: categories ? categories.map((category, index) => `${index !== 0 ? `/` : ``}${category.name}`).join("") : ""
          }]
        }
      }
    });
  } catch (err) {
    console.log(err);
  }
}
export function yandexPurchase(id: any, amount: any, cartItems: any, items: any) {
  try {
    window.dataLayer = window.dataLayer || [];
    return dataLayer.push({
      ecommerce: {
        currencyCode: "RUB",
        purchase: {
          actionField: {
            id: id
          },
          products: items.map(good => {
            const findedItem = cartItems.find(item => item.id === good.product_id);
            return {
              id: good.id,
              name: findedItem.name,
              quantity: good.quantity,
              price: findedItem.price_discount,
              category: findedItem.breadcrumb ? findedItem.breadcrumb.map((breadcrumb, index) => `${index !== 0 ? `/` : ``}${breadcrumb.name}`).join("") : ""
            };
          })
        }
      }
    });
  } catch (err) {
    console.log(err);
  }
}