export const keyGenerator = () => {
  const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
  const sections = [8, 4, 4, 4, 12];
  let uuid = '';
  for (let section of sections) {
    for (let i = 0; i < section; i++) {
      uuid += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    uuid += '-';
  }
  return uuid.slice(0, -1);
};