import { TRequest } from "../../services/types/types";
import { requestClient } from "./request-client";
import { requestServer } from "./request-server";
const OHOTAKTIV_API_PROD = "https://api.ohotaktiv.ru/api/v2";

// const OHOTAKTIV_API_PROD = process.env.NEXT_PUBLIC_OHOTAKTIV_API_PROD;
export async function request(endpoint: string, options?: TRequest) {
  if (typeof window !== "undefined") {
    return requestClient(OHOTAKTIV_API_PROD + endpoint, options);
  } else {
    return requestServer(OHOTAKTIV_API_PROD + endpoint, options);
  }
}
export async function requestWithoutResponse(endpoint: string, options?: TRequest) {
  return fetch(OHOTAKTIV_API_PROD + endpoint, {
    ...options,
    headers: {
      ...options?.headers
      // Cookie: "wsr_nocache=1",
    },
    next: {
      revalidate: 2 * 60
    }
  });
}