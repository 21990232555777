import styles from "./toast.module.scss";
import { FC } from "react";
interface IToast {
  message: string;
  submessage: string;
}
export const Toast: FC<IToast> = ({
  message,
  submessage
}) => {
  return <>
      {/* <h2 className={styles.title}>{message}</h2> */}
      <p className={styles.text}>{submessage}</p>
    </>;
};