import { getCookie } from "./cookie";
import { notFound } from "next/navigation";
import { callMessage } from "./callMessage";
export const checkSuccess = (res: any) => {
  if (typeof window !== "undefined" && getCookie("refreshToken") && res.auth === false) {
    return res;
  }
  if (res.success === false && res.error_msg === "Вы не авторизованы") {
    window.location.href = "/";
  }
  if (res && res.success) {
    if (res.status && res.status === 404) {
      if (typeof window !== "undefined") {
        return notFound();
      }
    }
    return res;
  }
  if (typeof window !== "undefined") callMessage("Ошибка!", res.error_msg, false);
  return Promise.reject(res.error_msg);
};