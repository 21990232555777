import { sendErrorRequest } from "../api/send-error";
export const checkResponse = (res: Response, url: string) => {
  return res.ok ? res.json() : res.json().then(err => {
    const errMessege = `Ошибка запроса. CR. URL: ${url}. ${err}`;
    sendErrorRequest(errMessege);
    return Promise.reject(err);
  }).catch(err => {
    const errMessege = `Ошибка запроса. CR. URL: ${res.url}. ${err}`;
    sendErrorRequest(errMessege);
    return Promise.reject(err);
  });
};